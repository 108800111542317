<template>
    <div id="blog">
        <!-- Preloader Start -->
        <div class="preloader">
            <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-icon"><img
                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Logo-White.png" alt="">
                </div>
            </div>
        </div>
        <!-- Preloader End -->

        <!-- Header Start -->
        <SiteHeader></SiteHeader>
        <!-- Header End -->

        <!-- Page Header Start -->
        <div class="page-header bg-section parallaxie">
            <!-- Page Header Box Start -->
            <div class="page-header-box">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- Page Header Content Start -->
                            <div class="page-header-content">
                                <h1 class="wow fadeInUp">Best Holistic Life Magazine<br><span>and Ingomu</span></h1>
                                <nav class="wow fadeInUp" data-wow-delay="0.25s">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item">START YOUR 7-DAY FREE TRIAL TODAY</li>
                                    </ol>
                                </nav>
                            </div>
                            <!-- Page Header Content End -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page Header Box End -->
        </div>
        <!-- Page Header End -->

        <!-- Our Expertise Section Start -->
        <div class="our-expertise">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <!-- Our Expertise Content Start -->
                        <div class="our-expertise-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">7-DAY FREE TRIAL</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Start Your 7-Day<br><span>Free Trial
                                        Today!</span></h2>
                                <p class="wow fadeInUp" data-wow-delay="0.5s">Calling all Best Holistic Life Magazine
                                    readers: transform your life with the Ingomu App! Unlock unlimited access to expert
                                    coaches specializing in life, wellness, work, and soul growth. The coaches featured
                                    here are just a small selection of the many talented professionals available on the
                                    app. Enjoy over 60 live virtual group coaching sessions monthly, dive into 1,000+
                                    on-demand resources, and receive personalized guidance from our AI Coach Assistant,
                                    Eric. Track your progress and stay inspired with gamified badges and a vibrant
                                    social community.</p>
                                <p class="wow fadeInUp" data-wow-delay="0.5s">Start with a 7-day free trial, then
                                    continue for just $29/month—with the flexibility to cancel anytime. Take the first
                                    step toward a more balanced, fulfilling life today!</p>
                            </div>
                            <!-- Section Title End -->

                            <!-- Expertise Button Start -->
                            <div class="hero-btn wow fadeInUp row col-lg-12" data-wow-delay="0.4s">
                                    <div class="col-md-3 col-6 mb-3">
                                        <a href="https://apps.apple.com/us/app/ingomu/id1621410470" target="_blank">
                                            <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/app_badge.svg"
                                                alt="" class="h-50">
                                        </a>
                                    </div>
                                    <div class="col-md-3 col-6 mb-3">
                                        <a href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu"
                                            target="_blank">
                                            <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/google_badge.png"
                                                alt="" class="h-50">
                                        </a>
                                    </div>
                                    <div class="col-md-4 mb-3 thirditem">
                                        <div class="intro-video-box">
                                            <div class="video-play-button">
                                                <a href="https://www.youtube.com/watch?v=t2E7oKCK48Y"
                                                    class="popup-video" data-cursor-text="Play">
                                                    <i class="fa-solid fa-play"></i>
                                                </a>
                                                <p class="text-black">watch video</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <!-- Expertise Button End -->
                        </div>
                        <!-- Our Expertise Content End -->
                    </div>

                    <div class="col-lg-5">
                        <div class="expertise-list">
                            <!-- Expertise Item Start -->
                            <div class="expertise-item wow fadeInUp">
                                <div class="expertise-image">
                                    <figure class="image-anime">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-coach-theresa-byrne+Picture+App.png"
                                            alt="">
                                    </figure>
                                </div>
                                <div class="expertise-content text-left">
                                    <p>Theresa Byrne, CPF, CPQC, CCht<br>Boundaries 101</p>
                                </div>
                            </div>
                            <!-- Expertise Item End -->

                            <!-- Expertise Item Start -->
                            <div class="expertise-item wow fadeInUp" data-wow-delay="0.2s">
                                <div class="expertise-image">
                                    <figure class="image-anime">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-coach-rick-reynolds.png"
                                            alt="">
                                    </figure>
                                </div>
                                <div class="expertise-content text-left">
                                    <p>Rick Reynolds, CCHT<br>Fear to Freedom</p>
                                </div>
                            </div>
                            <!-- Expertise Item End -->

                            <!-- Expertise Item Start -->
                            <div class="expertise-item wow fadeInUp" data-wow-delay="0.6s">
                                <div class="expertise-image">
                                    <figure class="image-anime">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-coach-anita-avalos.png"
                                            alt="">
                                    </figure>
                                </div>
                                <div class="expertise-content text-left">
                                    <p>Anita Avalos, CEPC, CHHC, ACE GFI<br>Body Wisdom, Food Freedom</p>
                                </div>
                            </div>
                            <!-- Expertise Item End -->

                            <!-- Expertise Item Start -->
                            <div class="expertise-item wow fadeInUp" data-wow-delay="0.8s">
                                <div class="expertise-image">
                                    <figure class="image-anime">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-coach-bentley-murdock.png"
                                            alt="">
                                    </figure>
                                </div>
                                <div class="expertise-content text-left">
                                    <p>Bentley Murdock, NBC-HWC, MPSY, MINT<br>Ease vs. Disease</p>
                                </div>
                            </div>
                            <!-- Expertise Item End -->

                            <!-- Expertise Item Start -->
                            <div class="expertise-item wow fadeInUp" data-wow-delay="1s">
                                <div class="expertise-image">
                                    <figure class="image-anime">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-coach-sylvia-henderson.png"
                                            alt="">
                                    </figure>
                                </div>
                                <div class="expertise-content text-left">
                                    <p>Sylvia Henderson, MBA<br>Action and Accountability</p>
                                </div>
                            </div>
                            <!-- Expertise Item End -->

                            <!-- Expertise Item Start -->
                            <!-- <div class="expertise-item wow fadeInUp" data-wow-delay="1.2s">
                                <div class="expertise-image">
                                    <figure class="image-anime">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-coach-anjanette-sullival.png" alt="">
                                    </figure>
                                </div>
                                <div class="expertise-content text-left">
                                    <p>Anjanette Sullivan, MA<br>Leadership</p>
                                </div>
                            </div> -->
                            <!-- Expertise Item End -->

                            <!-- Expertise Item Start -->
                            <!-- <div class="expertise-item wow fadeInUp" data-wow-delay="1.4s">
                                <div class="expertise-image">
                                    <figure class="image-anime">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-coach-arlene-cohen-miller.png" alt="">
                                    </figure>
                                </div>
                                <div class="expertise-content text-left">
                                    <p>Arlene Cohen Miller, JD, PCC<br>Confidence and Self-Esteem</p>
                                </div>
                            </div> -->
                            <!-- Expertise Item End -->

                            <!-- Expertise Item Start -->
                            <div class="expertise-item wow fadeInUp" data-wow-delay="1.6s">
                                <div class="expertise-image">
                                    <figure class="image-anime">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-coach-tatiana-labello.png"
                                            alt="">
                                    </figure>
                                </div>
                                <div class="expertise-content text-left">
                                    <p>Tatiana LaBello, CLC<br>50: Fabulous, Fearless, and Free</p>
                                </div>
                            </div>
                            <!-- Expertise Item End -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Our Expertise Section End -->

        <!-- Our Testimonial Section Start -->
        <div class="our-testimonial bg-section" id="testimonials">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-8">
                        <div class="our-testimonial-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">Testimonials</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Success Stories
                                    <span>from Our Users</span>
                                </h2>
                            </div>
                            <!-- Section Title End -->
                        </div>
                    </div>

                    <div class="col-lg-8">
                        <!-- Testimonial Slider Start -->
                        <div class="testimonial-slider">
                            <div class="swiper">
                                <div class="swiper-wrapper" data-cursor-text="Drag">
                                    <!-- Testimonial Slide Start -->
                                    <div class="swiper-slide" v-for="(testimony, index) in testimonialsList"
                                        :key="index">
                                        <div class="testimonial-item">
                                            <!-- <div class="testimonial-slider-image">
                                                <figure class="image-anime">
                                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu_Logo.png" alt="">
                                                </figure>
                                            </div> -->

                                            <div class="testimonial-slider-content">
                                                <div class="testimonial-header">
                                                    <div class="testimonial-rating-company">
                                                        <img src="images/icon-testimonial-rating-4.svg" alt="">
                                                    </div>
                                                    <div class="testimonial-quote">
                                                        <img src="images/testimonial-quote.svg" alt="">
                                                    </div>
                                                </div>
                                                <div class="testimonial-rating">
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                </div>
                                                <div class="testimonial-content">
                                                    <p>{{ testimony.comment }}</p>
                                                </div>
                                                <div class="author-content">
                                                    <h3>{{ testimony.name }}</h3>
                                                    <p>{{ testimony.title }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Testimonial Slide End -->
                                </div>
                                <div class="testimonial-btn">
                                    <div class="testimonial-button-prev"></div>
                                    <div class="testimonial-button-next"></div>
                                </div>
                            </div>
                        </div>
                        <!-- Testimonial Slider End -->
                    </div>

                </div>
            </div>
        </div>
        <!-- Our Testimonial Section End -->

        <BlogSection />

        <!-- Our FAQs Section Start -->
        <div class="our-faqs bg-section mt-100 mb-60" id="faq">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <!-- Our FAQs Content Start -->
                        <div class="our-faqs-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">FAQ</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Answers to<br><span>Your
                                        Questions</span></h2>
                            </div>
                            <!-- Section Title End -->

                            <!-- FAQs CTA Box Start -->
                            <div class="faqs-cta-box wow fadeInUp" data-wow-delay="0.75s">
                                <div class="faqs-cta-content">
                                    <h3>Do you still have questions?</h3>
                                    <p>We're ready to help!</p>
                                    <router-link :to="{ name: 'contact' }" class="btn-default">Contact Us</router-link>
                                </div>
                            </div>
                            <!-- FAQs CTA Box End -->
                        </div>
                        <!-- Our FAQs Content End -->
                    </div>

                    <div class="col-lg-6">
                        <div class="our-faq-section">
                            <!-- FAQ Accordion Start -->
                            <div class="faq-accordion" id="faqaccordion">
                                <!-- FAQ Item Start -->
                                <div class="accordion-item wow fadeInUp" v-for="(faq, index) in faqGeneral"
                                    :key="index">
                                    <h2 class="accordion-header" :id="faq.id">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" :data-bs-target="faq.hash" aria-expanded="true"
                                            :aria-controls="faq.controls">
                                            {{ faq.question }}
                                        </button>
                                    </h2>
                                    <div :id="faq.controls" class="accordion-collapse collapse"
                                        :aria-labelledby="faq.id" data-bs-parent="#faqaccordion">
                                        <div class="accordion-body">
                                            <p v-html="faq.answer"></p>
                                        </div>
                                    </div>
                                </div>
                                <!-- FAQ Item End -->
                            </div>
                            <!-- FAQ Accordion End -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Our FAQs Section End -->

        <!-- Footer Start -->
        <SiteFooter></SiteFooter>
        <!-- Footer End -->
    </div>
</template>
<script>
import SiteHeader from "@/components/InfineHeader";
import SiteFooter from "@/components/InfineFooter";
import BlogSection from "@/components/BlogSection";
import $ from "jquery";
import { slicknav } from '../../../mixins/slicknav';
import { parrallaxie } from "../../../mixins/parallaxie";
import { mapActions, mapGetters } from "vuex";
import { reveal } from "../../../mixins/reveal";
import { testimonal } from "../../../mixins/testimonial";

const TIMEOUT = 1000;

export default {
    name: "bhlv4",
    metaInfo() {
        return {
            title: "Best Holistic Life Magazine and Ingomu | Ingomu App",
        }
    },
    components: {
        SiteHeader,
        SiteFooter,
        BlogSection
    },
    data() {
        return {
            testimonialsList: [
                {
                    name: "Aiden",
                    title: "Ingomu Subscriber",
                    comment: "I've been going through a tough time at work and it's been spilling over into my home life. I started using the app a while back and I've found so much support in the communities. They've really helped me come up with strategies to tackle the challenges, both at work and at home. They’ve boosted my confidence and helped me improve my relationships with my colleagues and family. I'm thankful for the impact this app has had on my life and career."
                },
                {
                    name: "Anastasia S.",
                    title: "Ingomu Subscriber",
                    comment: "Where else can you get affordable access to unlimited coaching when you need it? I’ve been able to control my anxiety by joining live sessions. I’m less stressed and more focused! Thanks."
                },
                {
                    name: "Caitlin",
                    title: "Ingomu Subscriber",
                    comment: "I'm a full-time working mom of two and let me tell you, life has been INSANE lately. Work stress, the kids, trying to keep it all together...it was seriously making me lose my mind! I was feeling so overwhelmed and anxious, I didn't think I could handle it anymore. Then I heard about this Ingomu coaching app and I was like, \"Why not?\" Worst case scenario, it's a waste of time, right? But OMG, it's been a total game-changer! The sessions have helped me so much with managing stress, finding balance, and actually being present for my family and work. I'm not gonna lie, it's kinda amazing how much better I feel. If you're struggling like I was, this app is 100% worth it."
                },
                {
                    name: "Chris W.",
                    title: "Ingomu Subscriber",
                    comment: "Life can be difficult to navigate for everyone. Life coaching is tremendously helpful for overcoming the challenges we all face, but historically it’s only been affordable for the well-off. Ingomu changes the game. It brings amazing coaches, covering a broad range of life, work, health, and spiritual topics, to the masses, at an incredibly reasonable price."
                },
                {
                    name: "Sara M.",
                    title: "Ingomu Subscriber",
                    comment: "Ingomu has completely transformed how I approach personal development. The live coaching sessions are incredible. I’ve gained so much clarity in my career and well-being. Worth every penny!"
                },
                {
                    name: "Marco R.",
                    title: "Ingomu Subscriber",
                    comment: "As a manager, I wanted to grow my leadership skills, and Ingomu has been a game-changer. The variety of expert coaches and on-demand sessions make it easy to fit into my schedule. Plus, the community and badges keep me motivated!"
                },
                {
                    name: "Lisa T.",
                    title: "HR Director",
                    comment: "We introduced Ingomu to our employees and the impact has been incredible. Our team is more engaged, focused, and proactive about personal growth. It’s an invaluable tool for any company looking to invest in their people."
                }
            ],
            faqGeneral: [
                {
                    id: "headingOne",
                    controls: "collapseOne",
                    hash: "#collapseOne",
                    question: "Who will be my coaches?",
                    answer: "You’ll have unlimited access to a diverse group of vetted life, work, wellness, and soul coaches. Many are certified in their areas of expertise."
                },
                {
                    id: "headingTwo",
                    controls: "collapseTwo",
                    hash: "#collapseTwo",
                    question: "What types of topics do coaches cover?",
                    answer: "Coaches specialize in areas like personal development, leadership, health and wellness, career growth, and mindfulness. There are over 30 different coaches who can help you with the support you need when you need it."
                },
                {
                    id: "headingThree",
                    controls: "collapseThree",
                    hash: "#collapseThree",
                    question: "How can I access Ingomu?",
                    answer: "Ingomu is available via our app for iOS and Android, which can be downloaded from the <a href=\"https://apps.apple.com/us/app/ingomu/id1621410470\" target=\"_blank\">Apple App Store<\/a> or <a href=\"https://play.google.com/store/apps/details?id=com.ingomu.ingomu\" target=\"_blank\">Google Play<\/a>."
                },
                {
                    id: "headingFour",
                    controls: "collapseFour",
                    hash: "#collapseFour",
                    question: "What is included in my subscription?",
                    answer: "Your subscription provides unlimited access to live virtual group coaching sessions, more than 1,000 on-demand recorded sessions, one-on-one messaging with coaches, engagement in discussions between sessions, and AI coaching assistant, Eric. You can get the support you need, whenever you need it."
                },
                {
                    id: "headingFive",
                    controls: "collapseFive",
                    hash: "#collapseFive",
                    question: "How are subscriptions priced?",
                    answer: "Subscriptions are $29/month after a 7-day free trial. An annual plan is $290. For businesses, schools, or organizations, contact us for volume pricing."
                },
                {
                    id: "headingSix",
                    controls: "collapseSix",
                    hash: "#collapseSix",
                    question: "Can I try the app before signing up?",
                    answer: "Yes! Enjoy a 7-day free trial with full access. Download the app for <a href=\"https://apps.apple.com/us/app/ingomu/id1621410470\" target=\"_blank\">iOS<\/a> or <a href=\"https://play.google.com/store/apps/details?id=com.ingomu.ingomu\" target=\"_blank\">Android<\/a> to get started."
                },
                {
                    id: "headingSeven",
                    controls: "collapseSeven",
                    hash: "#collapseSeven",
                    question: "How do I cancel my subscription?",
                    answer: "Cancel anytime through your device’s App Store or Play Store. Ensure cancellation occurs at least 24 hours before your renewal date to avoid charges."
                },
                {
                    id: "headingEight",
                    controls: "collapseEight",
                    hash: "#collapseEight",
                    question: "How do I delete my account and data?",
                    answer: "In the app, go to the Account menu and select “Delete Account” under My Account. Your account and data will be removed immediately."
                },
            ]
        }
    },
    mounted() {
        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
        }
        var $window = $(window);

        // /* Preloader Effect */
        // $window.on('load', function () {
        //     $(".preloader").fadeOut(600);
        // });

        $(function () {
            /* Preloader Effect */
            setTimeout(function () {
                $(".preloader").fadeOut(600);
            }, 600);

            $('.popup-video').magnificPopup({
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: true
            });

            /* Animated Wow Js */
            new WOW().init();

            // Parallax
            parrallaxie()

            // Reveal
            reveal()

            /* Slick Menu JS */
            slicknav()

            // Testimonial 
            testimonal()

        })
    },
    methods: {
        strippedHtml(htmlText) {
            return htmlText.replace(/<[^>]+>/g, '').split(' ').slice(0, 25).join(" ")
        },
        imageLink(url) {
            // return unescape(url.substring(0, url.indexOf("&token=")))
            return unescape(url.substring(0, url.indexOf("&token=")))
        },
        scrollTo(hashtag) {
            // setTimeout(() => { document.getElementById(hashtag.slice(1)).scrollIntoView() }, TIMEOUT)
            setTimeout(() => {
                const element = document.getElementById(hashtag.slice(1)); // Your target element
                const headerOffset = 150;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }, TIMEOUT)
        },
    }
}
</script>
<style scoped>
.page-header {
    background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-and-best-holistic-life-magazine.png?v2);
}

.post-featured-image figure a {
    cursor: pointer;
}

.video-play-button {
    display: inline-flex;
}

.h-50 {
    height: 50px !important;
}

.text-left {
    text-align: left;
}

.our-faqs {
    background: var(--white-color);
    padding: 100px 0;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-100 {
    margin-bottom: 100px;
}

.testimonial-slider-content,
.testimonial-slider-image {
    width: 100%;
}

@media (max-width:480px) {
    .thirditem {
        margin-top: 20px;
    }

    .hero {
        background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-blog-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
</style>