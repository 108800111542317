<template>
    <div id="contact">
        <!-- Preloader Start -->
        <div class="preloader">
            <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-icon"><img
                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Logo-White.png" alt="">
                </div>
            </div>
        </div>
        <!-- Preloader End -->

        <!-- Header Start -->
        <SiteHeader></SiteHeader>
        <!-- Header End -->

        <!-- Page Header Start -->
        <div class="page-header bg-section parallaxie">
            <!-- Page Header Box Start -->
            <div class="page-header-box">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- Page Header Content Start -->
                            <div class="page-header-content">
                                <h1 class="wow fadeInUp">Welcome to <span>Ingomu</span></h1>
                                <nav class="wow fadeInUp" data-wow-delay="0.25s">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><router-link
                                                :to="{ name: 'home' }">home</router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Welcome</li>
                                    </ol>
                                </nav>
                            </div>
                            <!-- Page Header Content End -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page Header Box End -->
        </div>
        <!-- Page Header End -->

        <!-- Page Contact Us Start -->
        <div class="page-contact-us">
            <div class="container">

                <div class="row section-row align-items-center">
                    <div class="col-lg-7 col-md-9">
                        <!-- Section Title Start -->
                        <div class="section-title">
                            <h3 class="wow fadeInUp">WELCOME</h3>
                            <h2 class="wow fadeInUp" data-wow-delay="0.25s">Welcome to Ingomu <br><span>Your Guide to Getting Started</span></h2>
                        </div>
                        <!-- Section Title End -->
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-8">
                        <!-- Contact Us Form Start -->
                        <div class="contact-us-form">
                            <!-- Contact Us Title Start -->
                            <div class="contact-us-title">
                                <h3 class="wow fadeInUp textnone">Welcome to Ingomu!</h3>
                                <p class="wow fadeInUp mb-30" data-wow-delay="0.2s">
                                    We're thrilled to have you join our coaching community, where growth, well-being, and success are within reach. To help you get started, we've created a series of quick, helpful videos below. These guides will walk you through the app's features, show you how to connect with coaches, and empower you to make the most of your coaching journey.
                                </p>
                                <p class="wow fadeInUp mb-30" data-wow-delay="0.2s">
                                    Dive in and explore all that Ingomu has to offer. If you have any questions, we're just a <router-link :to="{ name: 'contact' }">click away.</router-link> Here's to unlocking your full potential—let’s get started!
                                </p>
                            </div>
                            <!-- Contact Us Title End -->



                        </div>
                        <!-- Contact Us Form End -->
                    </div>

                    <div class="col-lg-4">
                        <!-- Contact Information Start -->
                        <div class="contact-information">
                            <!-- Contact Info Item Start -->
                            <div class="contact-info-item wow fadeInUp">
                                
                                <!-- Footer Contact Information Start -->
                                <div class="contact-info-contant">
                                    <h3>Connect With Us</h3>
                                </div>
                                <!-- Footer Contact Information End -->

                                <!-- Footer Social Link Start -->
                                <!-- Post Social Links Start -->
                                <div class="post-social-sharing wow fadeInUp" data-wow-delay="0.5s">
                                    <ul>
                                        <li><a href="https://www.linkedin.com/company/ingomu" target="_blank"><i
                                                    class="fa-brands fa-linkedin-in"></i></a></li>
                                        <li><a href="https://www.instagram.com/ingomu.coaching/" target="_blank"><i
                                                    class="fa-brands fa-instagram"></i></a></li>
                                        <li><a href="https://www.youtube.com/@ingomu" target="_blank"><i
                                                    class="fa-brands fa-youtube"></i></a></li>
                                        <li><a href="https://www.facebook.com/ingomu.coaching/" target="_blank"><i
                                                    class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.pinterest.com/ingomu_coaching/"><i
                                                    class="fa-brands fa-pinterest"></i></a></li>
                                    </ul>
                                </div>
                                <!-- Post Social Links End -->
                                <!-- Footer Social Link End -->
                            </div>
                            <!-- Contact Info Item End -->


                            <!-- Contact Info Item Start -->
                            <div class="contact-info-item wow fadeInUp" data-wow-delay="0.25s">
                                <!-- Contact Info Contact Start -->
                                <div class="contact-info-contant">
                                    <h3>Get The App</h3>
                                </div>
                                <!-- Contact Info Contact End -->

                                <!-- Contact Info Body Start -->
                                <div class="contact-info-body">
                                    <div class="col-lg-12 row">
                                        <div class="col-lg-12 col-12 mb-3">
                                            <a href="https://apps.apple.com/us/app/ingomu/id1621410470" target="_blank">
                                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/app_badge.svg"
                                                    alt="" class="h-50 mr-20">
                                            </a>
                                            <a href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu"
                                                target="_blank">
                                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/google_badge.png"
                                                    alt="" class="h-50">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Contact Info Body End -->
                            </div>
                            <!-- Contact Info Item End -->

                        </div>
                        <!-- Contact Information End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Contact Us End -->

            <!-- Page Blog Start -->
    <div class="page-blog pt-0">
        <div class="container">

            <div class="row section-row align-items-center">
                    <div class="col-lg-7 col-md-9">
                        <!-- Section Title Start -->
                        <div class="section-title">
                            <h3 class="wow fadeInUp">TIPS AND RESOURCES</h3>
                            <h2 class="wow fadeInUp" data-wow-delay="0.25s">Explore Ingomu <br><span>Helpful Videos to Get You Started</span></h2>
                        </div>
                        <!-- Section Title End -->
                    </div>
                </div>

            <div class="row">
                <div class="col-lg-4 col-md-6" v-for="(video, index) in videosList" :key="index">
                    <!-- Post Item Start -->
                    <div class="post-item wow fadeInUp">
                        <!-- Post Featured Image Start -->
                        <div class="intro-video-box mb-30 pb-30 borderbottom">
                                    <!-- Sidebar Image Start -->
                                    <div class="sidebar-img">
                                        <a :href="video.url"
                                            class="popup-video" data-cursor-text="Play">
                                            <div class="card br-20">
                                                <img :src="video.thumbnail" alt="" class="card-img drk br-20">
                                                <img src="../../../assets/v2/images/icon/170.svg" alt=""
                                                    class="card-img-overlay w-auto">
                                            </div>
                                        </a>
                                    </div>
                                </div>
                        <!-- Post Featured Image End -->

                        <!-- Post Item Content Start -->
                        <div class="post-item-content">
                            <h2>{{ video.title }}</h2>
                            <!-- <p>Quick walkthrough of the app to help you get started.</p> -->
                        </div>
                        <!-- Post Item Content End -->
                    </div>
                    <!-- Post Item End -->
                </div>

            </div>
        </div>
    </div>
    <!-- Page Blog End -->

    <BlogSection bgsection="bg-section" />
    
        <!-- Page Book Appointment Start -->
        <div class="page-book-appointment bg-section mb-60">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <!-- Book Appointment Content Start -->
                        <div class="book-appointment-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp" data-wow-delay="0.2s">Weekly tips in your inbox</h3>
                                <h2 class="wow fadeInUp">ACTIONABLE TIPS<br><span>FOR A BETTER LIFE</span></h2>
                            </div>
                            <!-- Section Title Start -->
                        </div>
                        <!-- Book Appointment Content End -->
                    </div>

                    <div class="col-lg-7">
                        <!-- Book Appointment Form Start -->
                        <div class="appointment-form">
                            <p class="wow fadeInUp">By subscribing to our newsletter, you allow us to contact you at the
                                email address provided. You may unsubscribe at any time. Your information will never be
                                shared or sold.</p>

                            <!-- Appointment Form Start -->
                            <form id="newsletterForm" action="#" method="POST" data-toggle="validator"
                                class="wow fadeInUp" data-wow-delay="0.25s">
                                <div class="row">
                                    <div class="form-group col-md-12 mb-4">
                                        <input type="email" name="email" class="form-control" id="email"
                                            placeholder="Enter your email" required>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="col-md-12">
                                        <button type="submit" class="btn-default mb-25">Subscribe</button>
                                        <div id="msgSubmit" class="h3 hidden"></div>
                                    </div>
                                </div>
                            </form>
                            <!-- Appointment Form End -->
                        </div>
                        <!-- Book Appointment Form End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Book Appointment End -->

        <!-- Footer Start -->
        <SiteFooter></SiteFooter>
        <!-- Footer End -->
    </div>
</template>
<script>
import SiteHeader from "@/components/InfineHeader";
import SiteFooter from "@/components/InfineFooter";
import BlogSection from "@/components/BlogSection";
import $ from "jquery";
import { slicknav } from '../../../mixins/slicknav';
import { parrallaxie } from "../../../mixins/parallaxie";
import { mapActions, mapGetters } from "vuex";
import { reveal } from "../../../mixins/reveal";
import { validator } from "../../../mixins/validator";
import posthog from 'posthog-js';
import Swal from "sweetalert2";

export default {
    name: "welcome",
    metaInfo() {
        return {
            title: "Welcome to Ingomu | Ingomu App",
        }
    },
    components: {
        SiteHeader,
        SiteFooter,
        BlogSection
    },
    data() {
        return {
            videosList: [
                {
                    url: "https://www.youtube.com/watch?v=HerARU7lRXA",
                    title: "How to login to the Ingomu app",
                    thumbnail: "https://ingomulibrary.s3.us-east-1.amazonaws.com/images/HerARU7lRXA.jpg"
                },
                {
                    url: "https://www.youtube.com/watch?v=tKMDivHym4o",
                    title: "Your personalized home screen",
                    thumbnail: "https://ingomulibrary.s3.us-east-1.amazonaws.com/images/tKMDivHym4o.jpg"
                },
                {
                    url: "https://www.youtube.com/watch?v=ePycKoeVK4I",
                    title: "Meet Eric, your personal AI Coach Assistant",
                    thumbnail: "https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ePycKoeVK4I.jpg"
                },
                {
                    url: "https://www.youtube.com/watch?v=_qhLjmy5Hiw",
                    title: "How to watch a live stream",
                    thumbnail: "https://ingomulibrary.s3.us-east-1.amazonaws.com/images/_qhLjmy5Hiw.jpg"
                }
            ]
        }
    },
    methods: {
        ...mapActions(["contactUs", "newsletterConsumer"]),
        submit(email) {
            var _this = this;
            _this
                .newsletterConsumer({
                    email: email
                })
        },
        strippedHtml(htmlText) {
            return htmlText.replace(/<[^>]+>/g, '').split(' ').slice(0, 25).join(" ")
        },
        imageLink(url) {
            // return unescape(url.substring(0, url.indexOf("&token=")))
            return unescape(url.substring(0, url.indexOf("&token=")))
        },
    },
    mounted() {
        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
        }
        posthog.capture('$pageview')

        var $window = $(window);
        var $this = this;

        $(function () {
            /* Preloader Effect */
            setTimeout(function () {
                $(".preloader").fadeOut(600);
            }, 600);

            $('.popup-video').magnificPopup({
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: true
            });

            /* Animated Wow Js */
            new WOW().init();

            // Parallax
            parrallaxie()

            // Reveal
            reveal()

            /* Slick Menu JS */
            slicknav()

            validator((newsletterForm) => {
                let email = newsletterForm[0]["value"];
                posthog.capture('home_newsletter', {
                    'email': this.email
                });
                $this.submit(email);
                Swal.fire({
                        title: "",
                        text: "Thank you for subscribing. Welcome to our community! Look for your first coaching tips shortly.",
                        icon: "success",
                        confirmButtonClass: "btn btn-secondary",
                        heightAuto: true,
                    });
            })
        })

    },
    beforeDestroy() {
        posthog.capture('$pageleave')
    },

}
</script>
<style scoped>
.page-header {
    background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Welcome%3Dto-ingomu.png);
}

.mb-25 {
    margin-bottom: 25px;
}

.post-social-sharing {
    text-align: left;
    margin-top: 30px;
}

.h-50 {
    height: 50px !important;
}

.mr-20 {
    margin-right: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pt-0 {
    padding-top: 0;
}

.borderbottom {
    border-bottom: 1px solid var(--divider-color);
}

.textnone {
    text-transform: none;
}

.sidebar-cta-Body {
    background: black;
    padding: 0;
}

.w-auto {
    width: auto !important;
    margin: auto;
}

.drk {
    filter: brightness(0.6);
}

.our-blog {
    background: var(--white-color);
    padding: 100px 0;
}

.br-20 {
    border-radius: 20px;
}

@media (max-width:480px) {
    .page-header {
        background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-welcome-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .service-catagery-list {
        margin-top: 30px;
    }
}
</style>